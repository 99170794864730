<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
		<v-col cols="12">
			<v-card class="shadowCard">
			  <v-card-title primary-title>
					<span class="text-subtitle-1">Horas de observación</span>
					<v-spacer></v-spacer>

					<v-btn
					  color="orange"
					  dark
					  class="mr-2"
					  @click="dialog = true"
					  small
					  tile
					  v-if="!director"
					>
					  <v-icon left small>mdi-plus</v-icon>
					  Agregar
					</v-btn>

					<v-btn
					  color="primary"
					  dark
					  class="text-capitalize"
					  @click="initialize()"
					  small
					  tile
					>
					  <v-icon left small>mdi-refresh</v-icon>
					  Actualizar
					</v-btn>

			  </v-card-title>

			  <v-card-text class="black--text">
  				<v-row>
  					<v-col cols="12" md="6" lg="4">
            <v-select
              :items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana 3', value: 3},{ bloque: 'Semana 4', value: 4}]"
              v-model="semana"
              label="SEMANA"
              filled
              dense
              item-text="bloque"
              item-value="value"
              single-line
              clearable
            ></v-select>
          </v-col>

  					<!-- Seleccionar el ciclo -->
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-autocomplete
                label="Ciclo"
                id="id"
                filled
                dense
                v-model="ciclo"
                :items="ciclos"
                item-text="ciclo"
                item-value="ciclo"
                clearable
                single-line
              ></v-autocomplete>
            </v-col>
  				</v-row>

				  <!-- Estatus -->
				  <v-data-table
						:headers="headers"
						:items="filterClases"
						class="elevation-0"
						:search="search"
						:mobile-breakpoint="100"
				    dense
				  >
						<!-- Valores que ocupan botons, chips, iconos, etc -->
						<template v-slot:item.activo_sn="{ item }">
						  <v-chip 
							v-if="item.activo_sn == 1"
							small
							color="green"
							dark
						  >Si</v-chip>

						  <v-chip 
							v-else
							small
							color="error"
							dark
						  >No</v-chip>
						</template>

						<template v-slot:item.cumplio="{ item }">
							<v-checkbox 
								v-model="item.cumplio" 
								:value="1"
								@change="updateActividad( item ) "
							></v-checkbox>
						</template>
					  
						<template v-slot:item.actions="{ item }">
							<v-btn 
								color="error" 
								x-small
								@click="deleteItem(item)"
								class="mr-2"
							>
							  <v-icon small> mdi-delete</v-icon>
							</v-btn>
						</template>

						<template v-slot:no-data>
						  <v-btn
							color="primary"
							@click="initialize"
							small
						  >
							Actualizar
						  </v-btn>
						</template>

				  </v-data-table>
        </v-card-text>
			</v-card>
		</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
  	  v-model="dialog"
  	  max-width="600px"
  	  persistent
	 >
	  <v-card>
  		<v-card-title>
  		  <span class="text-subtitle-1">{{ formTitle }}</span>
  		  <v-spacer></v-spacer>
  		  <span class="text-subtitle-1">
  			<strong>ID: {{ editedItem.idhoras_prueba }}</strong>
  		  </span>
  		</v-card-title>

  		<v-card-text>
  		  <v-row>

    			<v-col cols="12" md="6" lg="4">
            <v-select
              :items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana 3', value: 3},{ bloque: 'Semana 4', value: 4}]"
              v-model="editedItem.semana"
              label="SEMANA"
              filled
              dense
              item-text="bloque"
              item-value="value"
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-autocomplete 
              filled 
              dense 
              clearabl
              v-model="editedItem.id_usuario" 
              :items="usuarios"
              label="Selecciona un usuario" 
              hide-details
              item-text="datos_completos" 
              item-value="id_usuario"             
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete 
              filled 
              dense 
              clearabl
              v-model="editedItem.id_grupo" 
              :items="grupos"
              label="Selecciona un grupo" 
              hide-details
              item-text="grupo" 
              item-value="id_grupo"             
            >
            </v-autocomplete>
          </v-col>

    			<v-col cols="12" md="12">
    			  <v-text-field
      				filled
      				dense
      				clearable
      				v-model="editedItem.horas"
      				label="Cantidad de horas"
      				hide-details
    			  >
    			  </v-text-field>
    			</v-col>
  		  </v-row>
  		</v-card-text>

  		<v-card-actions>
  		  <v-btn
    			color="black"
    			tile
    			dark
    			small
    			@click="close"
  		  >
  			  <v-icon small left>mdi-cancel</v-icon>
  			  Cancelar
  		  </v-btn>
  		  <v-spacer></v-spacer>
  		  <v-btn
    			color="green darken-1"
    			class=""
    			tile
    			dark
    			small
    			@click="save"
  		  >
    			<v-icon small left>mdi-content-save</v-icon>
    			Guardar
  		  </v-btn>
  		</v-card-actions>
	  </v-card>
	</v-dialog>

	<!-- Dialogo para eliminar -->
	<v-dialog v-model="dialogDelete" max-width="500px">
	  <v-card>
  		<v-card-text>
  			<v-row justify="space-around">
  				<v-col cols="6">
  					<v-card class="elevation-0" height="100%" style="display: table;">
  						<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
  							<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la información?
  							<v-card-actions class="pt-6">
  								<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
  							  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
  							  <!-- Guardar la información  -->
  							  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
  							</v-card-actions>
  						</div>
  					</v-card>
  				</v-col>
  				<v-col cols="5">
  					<v-img src="@/assets/borrar.png"></v-img>
  				</v-col>
			  </v-row>
  		</v-card-text>
	  </v-card>
	</v-dialog>

	<!-- Dialog de carga -->
	<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
	<carga                v-if="cargar"/>

	</v-container>
</template>
<script>
  import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

	mixins: [ validarErrores, funcionesExcel ],

	components:{
	  Alerta,
	  carga,
	},

	data: () => ({
		// Alertas
		parametros:{
  		dialogError: false,
  		mensaje: '',
  		color: ''
	  },

	  respuestaAlerta:false,
	  loader: true,
	  cargar: false,

	  dialog: false,
	  dialogDelete: false,

		fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

	  editedIndex: -1,

	  editedItem: {
		  idhoras_prueba:0,
			id_usuario:0,
			id_ciclo:'',
			id_grupo:'',
			semana:'',
			costo:60,
			horas:'',
			total:'',
			tipo_clase: 0,
			fecha_creacion:null,
			deleted:0,
	  },

	  defaultItem: {
		  idhoras_prueba:0,
      id_usuario:0,
      id_ciclo:'',
      id_grupo:'',
      semana:'',
      costo:60,
      horas:'',
      total:'',
      tipo_clase: 0,
      fecha_creacion:null,
      deleted:0,
    },

	  // tabla
		search:'',
	  clases: [],
	  headers: [
  		{ text: 'Teacher'   , value: 'nombre_completo'    },
      { text: 'Grupo'     , value: 'grupo'          },
      { text: 'Ciclo'     , value: 'ciclo'          },
      { text: 'Semana'    , value: 'semana'          },
      { text: 'Horas'     , value: 'horas'          },
      { text: 'Costo'     , value: 'costo'          },
  		{ text: 'Total'     , value: 'total'          },
  		{ text: 'Actions'       , value: 'actions', sortable: false },
	  ],

    semana:0,
    ciclo:0,

	  objetivo:'',
		usuarios:[],
    grupos:[],
	  usuario: null,

	  fechaini: null,
	  fechafin: null,
	  cicloInf: null,
    ciclos:[],


	}),

	computed: {
		...mapGetters('login',['getdatosUsuario']),
	  formTitle () {
		  return this.editedIndex === -1 ? 'Agregar clase' : 'Editar clase'
	  },

    filterClases( ){


      let data = this.clases

      if( this.semana ){

        data = data.filter( el => el.semana == this.semana )

      }


      if( this.ciclo ){

        data = data.filter( el => el.ciclo.match( this.ciclo ) )

      }

      return data

    }
	},

	watch: {
	  dialog (val) {
		  val || this.close()
	  },
	  dialogDelete (val) {
		  val || this.closeDelete()
	  },
	},

	async created () {
	  await this.getUsuarios()
    await this.initialize()
    await this.getGrupos()
	  await this.getCiclos()
	},

	methods: {
	  initialize () {
  		this.cargar = true
  		this.clases = []

  		return this.$http.get('horas.observacion.list').then(response=>{
  		  this.clases       = response.data
  			this.cargar          = false
  		}).catch( error =>{
  		  this.validarError( error.response.data.message )
  		}).finally( () => { this.cargar = false })
	  },

    getCiclos () {
      this.cargar = true
      this.ciclos = []
      return this.$http.get('ciclos.activos.erp').then(response=>{
        this.ciclos = response.data.filter( el => el.ciclo.match('CICLO') && !el.ciclo.match('FE'))
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

	  getUsuarios( ){
  		this.usuarios = []
  			this.$http.get('usuarios.all.erp').then(response=>{
  				for(const i in response.data){
  					response.data[i].datos_completos = response.data[i].datos_completos.toUpperCase() 
  				}
  				this.usuarios = response.data
  			}).catch( error =>{
  		  this.validarError( error.response.data.message )
  		}).finally( () => { this.cargar = false })
	  },

    getGrupos( ){
      this.grupos = []
        this.$http.get('grupos.horas.dobles').then(response=>{
          this.grupos = response.data
        }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

	  editItem (item) {
  		this.editedIndex = this.objetivos.indexOf(item)
  		this.editedItem = Object.assign({}, item)
  		this.dialog = true
	  },

	  deleteItem (item) {
  		this.editedIndex = this.objetivos.indexOf(item)
  		this.editedItem = Object.assign({}, item)
  		this.dialogDelete = true
	  },

	  deleteItemConfirm () {
  		this.cargar = true
  		// Lo mandapos por el EP
  		this.editedItem.deleted = 1
  		this.$http.put('horas.observacion.update/' + item.idhoras_prueba, item).then(response=>{
  			this.cargar = true
  			this.closeDelete()
  			this.initialize()
  		}).catch( error =>{
  		  this.validarError( error.response.data.message )
  		}).finally( () => { this.cargar = false })
	  },

	  close () {
  		this.dialog = false
  		this.$nextTick(() => {
  		  this.editedItem = Object.assign({}, this.defaultItem)
  		  this.editedIndex = -1
  		})
	  },

	  closeDelete () {
  		this.dialogDelete = false
  		this.$nextTick(() => {
  		  this.editedItem = Object.assign({}, this.defaultItem)
  		  this.editedIndex = -1
  		})
	  },

	  save () {
  		this.cargar = true

  		if (this.editedIndex > -1) {
  			// Lo mandapos por el EP
  		  this.$http.put('horas.observacion.update/' + this.editedItem.idhoras_prueba, this.editedItem).then(response=>{
    			this.validarSuccess( response.data.message )
    			this.initialize()
  				this.close()
  			}).catch( error =>{
  		    this.validarError( error.response.data.message )
  		}).finally( () => { this.cargar = false })

  		} else {
  			// Lo mandapos por el EP
  		  this.$http.post('horas.observacion.add', this.editedItem).then(response=>{
  				this.close()
    			this.initialize()
  				this.cargar = false
  			}).catch( error =>{
  			 this.validarError( error.response.data.message )
  			}).finally( () => { this.cargar = false })
  		}
	  },

	  updateActividad( item ){
  		this.cargar = true

  		this.$http.put('horas.observacion.update/' + item.idhoras_prueba, item).then(response=>{
  			this.validarSuccess( response.data.message )
  			this.initialize()
  		}).catch( error =>{
  		  this.validarError( error.response.data.message )
  		}).finally( () => { this.cargar = false })

	  },

	},
  }
</script>


